<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <flavor-form v-if="flavor"
                :submit-text="pageTitle"
                :flavor="flavor"
                :is-edit="true"
                @on-submit="editAdmin">
    </flavor-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import flavorForm from './components/_EditForm'
import FlavorService from '@admin/services/FlavorService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, flavorForm },
  data () {
    return {
      pageTitle: '保存',
      flavor: null
    }
  },
  async created () {
    const { data } = await flatry(FlavorService.edit(this.$router.currentRoute.query.id))

    if (data) {
      this.flavor = data.data
    }
  },
  methods: {
    async editAdmin (store, success, callback) {
      const { data } = await flatry(FlavorService.edit(store.id, store))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/flavor/update', query: { id: data.data } })
        success()
      }

      callback()
    }
  }
}
</script>
